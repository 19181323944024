<template>
  <transition name="modal">
    <div class="modal-mask" @click="backdropClose" :class="['modal-mask--' + size]">
      <div class="modal-wrapper"
           @mousedown="closeModal()">
        <div class="modal-container"
             @mousedown="$event.stopPropagation()">

                    <div class="modal-header">
                        <slot name="header"></slot>

                        <span class="modal-cancel"
                              v-if="closeable"
                              @click="closeModal()">
              <icon name="cancel" width="30" color="black"></icon>
            </span>
                    </div>

                    <div class="modal-body">
                        <slot name="body">
                        </slot>
                    </div>

                    <div v-if="isCenteredFooter" class="modal-footer--centered">
                        <slot name="footer-left">
                        </slot>
                        <slot name="footer">
                        </slot>
                    </div>
                    <div v-else class="modal-footer">
                        <slot name="footer-left">
                        </slot>
                        <slot name="footer">
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
  import Icon from './Icon'
  import eventBus, {EVENT_TYPES} from '@/components/shared/EventBus.js';

  export default {
    name: 'modal',
    components: {
      Icon
    },
    props: {
      modalStyle: String,
      size: {
        type: String,
        default: 'lg'
      },
      isCenteredFooter: {
        type: Boolean,
        default: false
      },
      closeable: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      shouldClose: function (evnt) {
        if (!evnt) {
          return false
        }
        const parents = this.$getParents(evnt.target)
        const result = parents.some(function (element) {
          if (!element.classList) {
            return false
          }
          return element.classList.contains('close-btn')
        })

        /* istanbul ignore next */
        if (evnt.target.classList.contains('modal-mask') || result) {
          return this.closeModal()
        }
      },
      closeModal() {
        this.$emit('close');
        eventBus.$emit('closeModalTooltip');
      },
      backdropClose(e) {
        if (this.closeable) {
          this.shouldClose(e);
        }
      }
    },
    mounted() {
      document.body.classList.add('modal-open');
    },
    destroyed() {
      document.body.classList.remove('modal-open');
    }
  }
</script>

<style lang="scss" scoped>
    @import "./scss/variables";
    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';

    .modal-open {
        overflow: hidden;
        padding-right: 17px;

        .fixed-header & header {
            z-index: 12;
        }
    }

    .modal-header,
    .modal-footer {
        border: 0;
    }

    .modal-footer {
        width: 100%;
        justify-content: space-between;

        &--centered {
          margin-top: 1rem;
          display: flex;
          justify-content: center;
        }

        ::v-deep .btn {
          width: 100%;
          white-space: break-spaces;
        }
    }

    .modal-mask {
        position: fixed;
        z-index: $zIndexModal;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, .5);
        transition: opacity .3s ease;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .modal-wrapper {
        max-width: 800px;
        margin: 200px auto 60px auto;
        padding: 0 16px;
    }

    .modal-container {
        position: relative;
        // max-width: 420px;
        border: 1px solid $lightGrey;
        box-sizing: border-box;
        margin: 0 auto;
        padding: 30px 20px 26px;
        background-color: #fff;
        border-radius: 3px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
        transition: all .3s ease;
    }

    .modal-cancel {
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
        &:hover {
            opacity: .5;
        }
    }

    .modal-header {
        margin-top: 0;
        color: $primary;
        font-size: 1.4rem;
        text-align: center;
    }

    .modal-header h3 {
        margin-top: 0;
        color: $primary;
        font-size: 1.4rem;
        text-align: center;
    }

    .modal-body {
        margin-top: 8px;
        padding: .5rem 1rem;

        @include media-breakpoint-down(sm) {
          padding: 0;

          ::v-deep .btn {
            white-space: break-spaces;
          }
        }
    }

    .modal-default-button {
        float: right;
    }

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    .modal-mask {
        &--sm {
            .modal-wrapper {
                max-width: 500px;
            }
        }

        &--md {
            .modal-wrapper {
                max-width: 650px;
            }
        }
    }
</style>
