import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
import axios from 'axios';
import { getSession, setSession, clearSession } from '@/services/session';
import { get as getValue, find } from 'lodash';
import i18n from '@/i18n';
import contract from './modules/contract';
import phishing from './modules/phishing';
import offer from './modules/offer';
import { loadLanguageAsync } from '@/i18n';
import router from '@/router';

Vue.use(Vuex);

axios.defaults.baseURL = process.env.VUE_APP_API_BASE;
Vue.http = Vue.http2 = Vue.prototype.$http = axios;

// TODO: remove this line after server side will be ready
let id = 24;

// local storage (fix safari bug)
const noStorageData = {}
let hasLocalStorage = false

if (window.localStorage) {
  try {
    const x = 'testStorage'
    window.localStorage.setItem(x, x)
    window.localStorage.removeItem(x)
    hasLocalStorage = true
  } catch(e) {
    hasLocalStorage = false
  }
}

const localStorageState = createPersistedState({
  paths: [
    'contract',
    'phishing',
    'offer',
    // 'checkout'
    // (!) Fixing safari "quotaExceededError":
    // Safari has the limitation for localStorage / sessionStorage size.
    // If we include all 'checkout' object here, then it will be caching a lot of the store data, wich will lead to "quotaExceededError"
    // As a workaround we could include only specific required data to cache and avoid to include big arrays and objects
    'checkout.application.activeStep',
    'checkout.application.affiliateBroker',
    'checkout.application.affiliateToken',
    'checkout.application.broker',
    'checkout.application.countryInterface',
    'checkout.application.cyberPrices',
    'checkout.application.industries',
    'checkout.application.initialLoadSortingRefresh',
    'checkout.application.inputs',
    'checkout.application.insTaxRate',
    'checkout.application.insuranceProperties', // a lot of data, but can't use app without it
    // 'checkout.application.insuranceProperties2_0', //a lot of data
    'checkout.application.insurers', //a lot of data, but can't use offer without it
    'checkout.application.insurersWithScores', // a lot of data, but can't use checkout without it
    'checkout.application.isCheckoutLoadingOverlay',
    // 'checkout.application.isInitialDataLoaded', // do not cache it, so the app will fetch required data from the server
    'checkout.application.isInitialDataLoading',
    'checkout.application.isPrintMode',
    'checkout.application.isScoreVisible',
    'checkout.application.legalInsurers',
    'checkout.application.mode',
    'checkout.application.modulesChanged',
    'checkout.application.offer',
    'checkout.application.overallBenchmark',
    // 'checkout.application.prices',
    // 'checkout.application.printInsurers',
    'checkout.application.public',
    // 'checkout.application.questions',
    'checkout.application.selectedModulesType',
    // 'checkout.application.serviceProvider',
    'checkout.application.steps',
    'checkout.application.style',
    'checkout.application.insurerSortingKey',
  ],
  storage: {
    // set
    setItem(key, value) {
      if (hasLocalStorage) {
        try {
          window.localStorage.setItem(key, value);
        } catch(err) {
          console.error('local storage setItem: ')
        }
      } else {
        noStorageData[key] = value;
      }
    },

    // get
    getItem(key) {
      if (hasLocalStorage) {
        try {
          return window.localStorage.getItem(key);
        } catch(err) {
          console.error('local storage getItem: ')
        }
        return window.localStorage.getItem(key);
      } else {
        return noStorageData[key];
      }
    },

    // remove
    removeItem(key) {
      if (hasLocalStorage) {
        try {
          return window.localStorage.removeItem(key);
        } catch(err) {
          console.error('local storage removeItem: ')
        }
      } else {
        noStorageData[key] = null;
      }
    }
  }
})
// /local storage (fix safari bug)

export default new Vuex.Store({
  modules: {
    contract,
    phishing,
    offer
  },
  plugins: [localStorageState],
  state: {
    application_name: 'offerView',
    users: [],
    $routeFrom: null,
    selectedUser: null,
    selectedUserToDelete: null,
    courses: [],
    courseProgresses: null,
    selectedCourse: null,
    selectedCourseProgress: null,
    companyProgress: null,
    companyStatistic: null,
    account: {
      user: {
        firstname: '',
        lastname: '',
        email: '',
        salutation: '',
        role: '',
        id: '',
        active: false,
        isFirstLogin: true,
        companyId: '',
        dataProtectionPolicyAccepted: false,
        lang: null,
      },
      company: {
        companyName: '',
        address: '',
        phoneNumber: '',
        employeeCount: null,
        id: '',
        ownerId: '',
      },
    },
    session: getSession(),
    isModalVisible: false,
    modal: {},
    isRouterLoading: false,
  },
  getters: {
    language: state => state.account.user.lang || 'de',
    token: state => state.session.token,
    logged: state => !!state.session.token && !!state.session.userId,
    role: (state) => state.session.role || state.account.user.role,
    agencyLogo: state => state.session.brokerAgencyLogo,
    agencyKey: state => state.session.brokerAgency,
    user: (state) => state.account.user,
    lead: (state) => state.account.user.relations || null,
    users: (state, getters) => state.users.map((user, index) => {
      user.display_id = index + 1
      return user
    }),
    isAdmin: (state, getters) => getters.role === 'admin',
    isEmployer: (state, getters) => getters.role === 'policyHolder',
    isTrial: (state, getters) => getters.role === 'trial',
    isManager: (state, getters) => getters.role === 'manager',
    isEmployee: (state, getters) => getters.role === 'employee',
    isFirstLogin: state => state.account.user.isFirstLogin,
    isSettingsDataFullFilled: ({ account: { user, company } }) => {
      return !!(user.firstname && user.lastname && user.email && user.salutation && company.companyName &&
        company.address && company.phoneNumber);
    },
    dataProtectionPolicyAccepted: state => state.account.user.dataProtectionPolicyAccepted,
    getCourseProgresses: state => state.courseProgresses,
    isAon: (state) => {
      const aonKeys = ['aon', 'aonaustriatest1', 'aonaustriatest2']
      const isAon = aonKeys.some(k => k === state.session.brokerAgency);
      return isAon
    }
  },
  mutations: {
    SET_ROUTER_LOADER: (state, boo) => {
      state.isRouterLoading = boo;
    },
    SET_LANGUAGE: (state, lang) => {
      state.account.user.lang = lang;
    },
    SET_ROUTE_FROM: (state, payload) => {
      state.$routeFrom = payload;
    },
    SET_USERS: (state, payload) => {
      state.users = payload;
    },
    ADD_OWNER: (state, payload) => {
      const alreadyExist = state.users.find( u => u.email === payload.email)
      if (alreadyExist) {
        console.error('error with adding employer')
        return
      }
      state.users = [payload, ...state.users]
    },
    SET_SESSION_DATA: (state, payload) => {
      state.session = payload;

      setSession(payload);
    },
    CLEAR_SESSION_DATA: (state) => {
      state.session = {
        token: null,
        userId: null,
        ttl: null,
        created: null,
        role: null,
      };

      clearSession();
    },
    START_INVITATION_USER: (state) => {
      state.selectedUser = {
        firstname: '',
        lastname: '',
        email: '',
        role: 'employee',
        active: false,
        salutation: '',
      };
    },
    START_CREATING_ADMIN: (state) => {
      state.selectedUser = {
        firstname: '',
        lastname: '',
        email: '',
        password: '',
      };
    },
    START_UPDATE_USER: (state, user) => {
      state.selectedUser = user;
    },
    SET_USER_DETAILS: (state, userDetails) => {
      state.selectedUser = userDetails;
    },
    START_DELETE_USER: (state, user) => {
      state.selectedUserToDelete = user;
    },
    UPDATE_USER: (state, updatedUser) => {
      state.users = state.users.map(user => {
        if (user.id === updatedUser.id) return updatedUser;

        return user;
      })
    },
    CLEAR_SELECTED_USER(state) {
      state.selectedUser = null;
      state.selectedUserToDelete = null;
    },
    SAVE_INVITED_USER: (state, payload) => {
      const d = new Date();
      payload.createdAt = d.toISOString();
      state.users.unshift(payload);
    },
    DELETE_USER: (state, userId) => {
      state.users = state.users.filter(user => user.id !== userId);
    },
    UPDATE_USER_ACCOUNT_DATA: (state, userData) => {
      state.account.user = { ...state.account.user, ...userData };
    },
    UPDATE_COMPANY_ACCOUNT_DATA: (state, companyData) => {
      state.account.company = { ...state.account.company, ...companyData };
    },
    CLEAR_ACCOUNT_DATA: (state) => {
      state.account.user = {
        firstname: '',
        lastname: '',
        email: '',
        salutation: '',
        role: '',
        id: '',
        active: false,
        isFirstLogin: true,
        companyId: '',
      };

      state.account.company = {
        companyName: '',
        address: '',
        employeeCount: null,
        id: '',
        ownerId: '',
      };
    },
    SET_COURSES: (state, coursesData) => {
      state.courses = coursesData;
    },
    UPDATE_COURSE: (state, { courseId, employeeIDs }) => {
      const course = find(state.courses, ['id', courseId]);

      course.employees = employeeIDs;

      state.courses = [...state.courses];
    },
    START_ASSIGNING_EMPLOYEES_TO_THE_COURSE: (state, course) => {
      state.selectedCourse = course;
    },
    SELECT_COURSE: (state, courseData) => {
      state.selectedCourse = courseData;
    },
    SET_COURSE_PROGRESSES: (state, courseProgresses) => {
      state.courseProgresses = courseProgresses;
    },
    SET_COURSE_PROGRESS: (state, courseProgress) => {
      state.selectedCourseProgress = courseProgress;
    },
    UPDATE_COURSE_PROGRESS: (state, courseProgressPatch) => {
      state.selectedCourseProgress = {
        ...state.selectedCourseProgress,
        ...courseProgressPatch,
      };
    },
    INCREASE_QUIZ_REPEAT_COUNT: (state) => {
      state.selectedCourseProgress.quizRepeatCount += 1;
    },
    SET_COMPANY_PROGRESS: (state, companyProgress) => {
      state.companyProgress = companyProgress;
    },
    SET_COMPANY_STATISTIC: (state, companyStatistic) => {
      state.companyStatistic = companyStatistic;
    },
    CLEAR_SELECTED_COURSE: (state) => {
      state.selectedCourse = null;
    },
    SET_MODAL: (state, data) => {
      state.isModalVisible = data.visible;
      state.modal = data.modal
    },
    SET_MODAL_PROPERTY: (state, {property, value}) => {
      Vue.set(state.modal, property, value)
    }
  },
  actions: {
    LOG_IN: async ({ commit }, { email, password }) => {
      try {
        const {
          data: {
            id: token,
            userId,
            ttl,
            brokerAgency,
            brokerAgencyLogo,
            created,
          }
        } = await Vue.http.post('/customers/login', { email, password });

        commit('SET_SESSION_DATA', {
          brokerAgency,
          brokerAgencyLogo,
          token,
          userId,
          ttl,
          created,
        });

        return { success: true };
      } catch (error) {
        console.error(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    LOGIN_SSO_ARTUS: async ({commit}, encryptedToken ) => {
      try {
        const {
          data: {
            id: token,
            userId,
            ttl,
            brokerAgency,
            brokerAgencyLogo,
            created,
          }
        } = await Vue.http2.post('customers/create-or-login?lead=artus&data=' + encryptedToken)

        commit('SET_SESSION_DATA', {
          brokerAgency,
          brokerAgencyLogo,
          token,
          userId,
          ttl,
          created,
        });

        return { success: true };
      } catch (error) {
        const { message } = getValue(error, 'response.data.error', error);
        throw message
      }
    },
    INIT_USER_DATA: async ({ state, commit, dispatch }, isForce) => {
      if (!isForce && state.account.user.id) return Promise.resolve();

      const { userId } = state.session;

      try {
        const { data: userData } = await Vue.http.get(`/customers/${userId}`);
        const userLang = userData.lang ? userData.lang : 'de';
        commit('UPDATE_USER_ACCOUNT_DATA', userData);
        dispatch('CHANGE_LANGUAGE', userLang);


        if (userData.role === 'policyHolder') {
          const { data: companyData } = await Vue.http.get(`/customers/${userId}/ownsOneCompany`);
          commit('UPDATE_COMPANY_ACCOUNT_DATA', companyData);
        } else {
          // console.log(userData.companyId);
          const { data: companyData } = await Vue.http.get(`/company/${userData.companyId}`);
          commit('UPDATE_COMPANY_ACCOUNT_DATA', companyData);
        }
      } catch (error) {
        console.error(error);
      }
    },
    REGISTER: async (state, { salutation, email, firstname, lastname, companyName, password, policyNumber = null, startDate = null, lead = null }) => {
      const data = {
        email: email,
        salutation: salutation,
        firstname: firstname,
        lastname: lastname,
        companyName: companyName,
        password: password,
        policyId: policyNumber,
        startDate: startDate,
        lead: "alte-leipziger",
        policy: false,
      };

      try {
        await Vue.http.post('/customers/create-employer/', data);

        return { success: true };
      } catch (error) {
        console.error(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }

      // return { success: true };

    },
    WELCOME_MODAL_SHOWN: async ({ state, commit }, patchData) => {
      const { userId } = state.session;

      try {
        const { data: userData } = await Vue.http.patch(`/customers/${userId}`, patchData);

        commit('UPDATE_USER_ACCOUNT_DATA', userData);

        return { success: true };
      } catch (error) {
        console.error(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    DATA_PROTECTION_POLICY_ACCEPT: async ({ state, commit }) => {
      const { userId } = state.session;

      try {
        const { data: userData } = await Vue.http.patch(`/customers/${userId}`, { dataProtectionPolicyAccepted: true });

        commit('UPDATE_USER_ACCOUNT_DATA', userData);

        return { success: true };
      } catch (error) {
        console.error(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    RESEND_INVITATION: async (state, email) => {
      try {
        await Vue.http.post('/customers/invite/resend', {
          email
        });

        return { success: true };
      } catch (error) {
        console.log(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    PASSWORD_RESET_REQUEST: async ({state}, {email, lead}) => {
      try {
        await Vue.http.post('/customers/reset', { email, lead });

        return { success: true };
      } catch (error) {
        console.log(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    PASSWORD_RESET_REQUEST_FOR_ADMIN: async (state, email) => {
      try {
        await Vue.http.post('/appuser/reset', { email });

        return { success: true };
      } catch (error) {
        console.log(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    GET_USERS: async ({ state, commit }) => {
      const { id } = state.account.company;

      try {
        const { data: users } = await Vue.http.get(`/company/${id}/employees`);

        commit('SET_USERS', users);

        return { success: true };
      } catch (error) {
        console.dir(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    GET_EMPLOYERS: async ({ commit }) => {
      try {
        const { data: employers } = await Vue.http.get('/customers?filter={"where":{"role":"policyHolder"}}');

        commit('SET_USERS', employers);

        return { success: true };
      } catch (error) {
        console.dir(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    ADD_EMPLOYER: async ({state, commit}) => {
      try {
        const id = state.account.company.id;
        if (state.account.user.role === 'employee') {
          return
        }

        // add owner to the array of users
        const {data: owner} = await Vue.http.get(`/company/${id}/owner`);
        commit('ADD_OWNER', owner);

        return {success: true};
      } catch (error) {
        console.dir(error);

        const {message} = getValue(error, 'response.data.error', error);

        return {success: false, message};
      }
    },
    GET_ADMINS: async ({ commit }) => {
      try {
        const { data: admins } = await Vue.http.get('/appuser');

        commit('SET_USERS', admins);

        return { success: true };
      } catch (error) {
        console.dir(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    GET_USER_DETAILS: async ({ state, commit }, userId) => {
      try {
        const { data: employerData } = await Vue.http.get(`/customers/${userId}`);
        const { data: company } = await Vue.http.get(`/customers/${userId}/ownsOneCompany`);

        commit('SET_USER_DETAILS', {
          ...employerData,
          company,
        });

        return { success: true };
      } catch (error) {
        console.dir(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    SEND_INVITATION_TO_USER: async ({ state, commit, dispatch }, { firstname, lastname, email, role }) => {
      try {
        const {
          data: {
            employeeId,
          }
        } = await Vue.http.post('/customers/invite-employee', {
          firstname,
          lastname,
          email,
          isManager: role === 'manager',
        });

        if (!employeeId) {
          return {
            success: false,
            message: i18n.t('users.email_already_exists'),
          };
        }

        commit('SAVE_INVITED_USER', {
          id: employeeId,
          firstname,
          lastname,
          email,
          role,
        });
        commit('CLEAR_SELECTED_USER');

        return { success: true };
      } catch (error) {
        console.dir(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    CREATE_NEW_ADMIN: async ({ state, commit, dispatch }, adminData) => {
      try {
        const { data: createdAdmin } = await Vue.http.post('/appuser', adminData);

        if (!createdAdmin.id) {
          return {
            success: false,
            message: i18n.t('users.email_already_exists'),
          };
        }

        commit('SAVE_INVITED_USER', createdAdmin);
        commit('CLEAR_SELECTED_USER');

        return { success: true };
      } catch (error) {
        console.dir(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    UPDATE_USER_DATA: async ({ state, commit }, { id, firstname, lastname, role, password }) => {
      const { id: companyId } = state.account.company;

      try {
        const { data: updatedUser } = await Vue.http.put(
          `/company/${companyId}/employees/${id}`,
          { firstname, lastname, password: password || undefined },
        );

        if (updatedUser.role !== role) {
          await Vue.http.post(
            `/customers/toggle-role/${id}`,
            { expectedRole: role },
          );

          updatedUser.role = role;
        }

        commit('UPDATE_USER', updatedUser);
        commit('CLEAR_SELECTED_USER');

        return { success: true };
      } catch (error) {
        console.dir(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    DELETE_USER: async ({ state, commit }, userId) => {
      const { id: companyId } = state.account.company;

      try {
        await Vue.http.delete(`/company/${companyId}/employees/${userId}`);

        commit('DELETE_USER', userId);
        commit('CLEAR_SELECTED_USER');

        return { success: true };
      } catch (error) {
        console.dir(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    DELETE_ADMIN: async ({ state, commit }, userId) => {
      try {
        await Vue.http.delete(`/appuser/${userId}`);

        commit('DELETE_USER', userId);
        commit('CLEAR_SELECTED_USER');

        return { success: true };
      } catch (error) {
        console.dir(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    DELETE_EMPLOYER: async ({ state, commit }, userId) => {
      try {
        await Vue.http.post(`/customers/delete-employer-completely/${userId}`);

        commit('DELETE_USER', userId);
        commit('CLEAR_SELECTED_USER');

        return { success: true };
      } catch (error) {
        console.dir(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    UPDATE_ACCOUNT_PASSWORD: async ({ commit }, passwordData) => {
      try {
        await Vue.http.post('/customers/change-password', passwordData);

        return { success: true };
      } catch (error) {
        console.dir(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    UPDATE_EMPLOYER_PASSWORD: async ({ state, commit }, { employerId, password }) => {
      try {
        const { data } = await Vue.http.patch(`/customers/${employerId}`, { password });

        return { success: true };
      } catch (error) {
        console.error(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    SET_BLOCK_STATE_FOR_EMPLOYER: async ({ state, commit }, { employerId, block }) => {
      try {
        const { data } = await Vue.http.post(`/customers/block-account/${employerId}`, { block });

        return { success: true };
      } catch (error) {
        console.error(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    UPDATE_ACCOUNT_EMAIL: async ({ state, commit }, newUserEmail) => {
      const { userId } = state.session;

      try {
        const { data } = await Vue.http.patch(`/customers/${userId}`, newUserEmail);

        commit('UPDATE_USER_ACCOUNT_DATA', data);

        return { success: true };
      } catch (error) {
        console.error(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    SET_ACCOUNT_DATA: async ({ state, commit }, newUserPath) => {
      const { userId } = state.session;

      try {
        const { data } = await Vue.http.patch(`/customers/${userId}`, newUserPath);

        commit('UPDATE_USER_ACCOUNT_DATA', data);

        return { success: true };
      } catch (error) {
        console.error(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    CHANGE_TEMPORARY_PASSWORD: async ({ state, commit }, newUserPassword) => {
      const { userId } = state.session;

      try {
        const { data } = await Vue.http.patch(`/customers/${userId}`, {
          password: newUserPassword,
          isFirstLogin: false,
        });

        commit('UPDATE_USER_ACCOUNT_DATA', data);

        return { success: true };
      } catch (error) {
        console.error(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    RESET_PASSWORD: async ({ state, commit }, newPassword) => {
      try {
        await Vue.http.post('/customers/reset-password', { newPassword });

        commit('CLEAR_SESSION_DATA');

        return { success: true };
      } catch (error) {
        console.error(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    RESET_PASSWORD_FOR_ADMIN: async ({ state, commit }, newPassword) => {
      try {
        await Vue.http.post('/appuser/reset-password', { newPassword });

        commit('CLEAR_SESSION_DATA');

        return { success: true };
      } catch (error) {
        console.error(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    SET_ACCOUNT_COMPANY_DATA: async ({ state, commit }, newCompanyPath) => {
      const { id } = state.account.company;

      try {
        const { data } = await Vue.http.patch(`/company/${id}`, newCompanyPath);

        commit('UPDATE_COMPANY_ACCOUNT_DATA', data);

        return { success: true };
      } catch (error) {
        console.error(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    UPDATE_EMPLOYER_DATA: async ({ state, commit }, { patch, userId }) => {

      try {
        const { data: userData } = await Vue.http.patch(`/customers/${userId}`, patch);

        commit('SET_USER_DETAILS', {
          ...state.selectedUser,
          ...userData,
        });

        return { success: true };
      } catch (error) {
        console.error(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    UPDATE_EMPLOYER_COMPANY_DATA: async ({ state, commit }, { patch, companyId }) => {
      try {
        const { data: company } = await Vue.http.patch(`/company/${companyId}`, patch);

        commit('SET_USER_DETAILS', {
          ...state.selectedUser,
          company,
        });

        return { success: true };
      } catch (error) {
        console.error(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    GET_COURSES: async ({ commit }) => {
      // TODO: update after server side will be ready (logic of assigning can be different)
      try {
        const { data } = await new Promise(ok => {
          // Emulate server response
          setTimeout(ok, 150, {
            data: [
              {
                id: '1',
                slug: 'social-engineering',
                image: require('@/assets/images/social-engineering.jpg'),
                section: 'trainings.cyber_security',
                title: 'trainings.social_engineering',
                // start_date: new Date(2019, 0, 30).toJSON(),
                employees: [],
                active: true,
              },
              {
                id: '2',
                slug: 'password-security',
                image: require('@/assets/images/password-security.jpg'),
                section: 'trainings.cyber_security',
                title: 'trainings.password_security',
                // start_date: new Date(2019, 0, 30).toJSON(),
                employees: [],
                active: true,
              },
              {
                id: '3',
                slug: 'email-security',
                image: require('@/assets/images/email-security.jpg'),
                section: 'trainings.cyber_security',
                title: 'trainings.email_security',
                // start_date: new Date(2019, 0, 30).toJSON(),
                employees: [],
                active: true,
              },
              {
                id: '4',
                slug: 'social-media',
                image: require('@/assets/images/social-media.jpg'),
                section: 'trainings.cyber_security',
                title: 'trainings.social_media',
                // start_date: new Date(2019, 0, 30).toJSON(),
                employees: [],
                active: true,
              },
              {
                id: '5',
                slug: 'byod',
                image: require('@/assets/images/byod.jpg'),
                section: 'trainings.cyber_security',
                title: 'trainings.byod',
                // start_date: new Date(2019, 0, 30).toJSON(),
                employees: [],
                active: true,
              },
              {
                id: '6',
                slug: 'connection-security',
                image: require('@/assets/images/connection-security.jpg'),
                section: 'trainings.cyber_security',
                title: 'trainings.connection_security',
                // start_date: new Date(2019, 0, 30).toJSON(),
                employees: [],
                active: true
              },
              {
                id: '7',
                slug: 'physical-security',
                image: require('@/assets/images/physical-security.jpg'),
                section: 'trainings.cyber_security',
                title: 'trainings.physical_security',
                // start_date: new Date(2019, 0, 30).toJSON(),
                employees: [],
                active: true,
              },
              {
                id: '8',
                slug: 'patchmanagement',
                image: require('@/assets/images/patchmanagement.jpg'),
                section: 'trainings.cyber_security',
                title: 'trainings.patchmanagement',
                // start_date: new Date(2019, 0, 30).toJSON(),
                employees: [],
                active: true,
              },
              {
                id: '9',
                slug: '5-golden-rules',
                image: require('@/assets/images/5-golden-rules.jpg'),
                section: 'trainings.cyber_security',
                title: 'trainings.5_golden_rules',
                // start_date: new Date(2019, 0, 30).toJSON(),
                employees: [],
                active: true,
              },
              {
                id: '10',
                slug: 'cyber-emergency',
                image: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/e-learning/section-images/cyber-notfall.jpg',
                section: 'trainings.cyber_security',
                title: 'trainings.cyber_emergency',
                employees: [],
                active: true,
              },
              {
                id: '11',
                slug: 'malware',
                image: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/e-learning/section-images/malware-image.jpg',
                section: 'trainings.cyber_security',
                title: 'trainings.malware',
                employees: [],
                active: true,
              },
              {
                id: '12',
                slug: 'remote-work',
                image: 'https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/e-learning/section-images/remote-work.jpg',
                section: 'trainings.cyber_security',
                title: 'trainings.remote',
                employees: [],
                active: true,
              }
            ]
          });
        });

        commit('SET_COURSES', data);

        return { success: true };
      } catch (error) {
        console.error(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    GET_COMPANY_PROGRESS: async ({ commit }) => {
      try {
        const { data: companyProgress } = await Vue.http.get('/progress/get-company-progress');

        commit('SET_COMPANY_PROGRESS', companyProgress);

        return { success: true };
      } catch (error) {
        console.error(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    GET_COMPANY_STATISTIC: async ({ commit }) => {
      try {
        const { data: companyStatistic } = await Vue.http.get('/statistics/get-company-stats');

        commit('SET_COMPANY_STATISTIC', companyStatistic);

        return { success: true };
      } catch (error) {
        console.error(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    GET_COURSE_DATA: async ({ commit }, courseSlug) => {
      try {
        const response = await Vue.http.get(`/trainingsLists/get-training/${courseSlug}?lang=${i18n.locale}`);
        commit('SELECT_COURSE', response.data);

        return { success: true };
      } catch (error) {
        console.error(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    GET_COURSE_PROGRESSES: async ({ commit }) => {
      try {
        const { data: courseProgresses } = await Vue.http.get('/progress/get-progress');

        commit('SET_COURSE_PROGRESSES', courseProgresses);

        return { success: true };
      } catch (error) {
        console.error(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    GET_COURSE_PROGRESS: async ({ commit }, courseSlug) => {
      try {
        const { data: courseProgress } = await Vue.http.get('/progress/get-single-progress/' + courseSlug);

        commit('SET_COURSE_PROGRESS', courseProgress);

        return { success: true };
      } catch (error) {
        console.error(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    SAVE_PROGRESS: async ({ commit }, updatedProgress) => {
      try {
        await Vue.http.post('/progress/save-progress', updatedProgress);

        commit('UPDATE_COURSE_PROGRESS', updatedProgress);

        return { success: true };
      } catch (error) {
        console.error(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    ASSIGN_ALL_EMPLOYEES_TO_THE_COURSE: async ({ dispatch }, forCourse) => {
      try {
        await Vue.http.post('/trainingsLists/assign-to-all', forCourse);

        await dispatch('GET_COMPANY_PROGRESS');

        return { success: true };
      } catch (error) {
        console.error(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    REVOKE_ALL_EMPLOYEES_FROM_THE_COURSE: async ({ dispatch }, fromCourse) => {
      try {
        await Vue.http.post('/trainingsLists/revoke-for-all', fromCourse);

        await dispatch('GET_COMPANY_PROGRESS');

        return { success: true };
      } catch (error) {
        console.error(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    ASSIGN_EMPLOYEE_TO_THE_COURSE: async ({ dispatch }, { trainingSlug, ownerId }) => {
      try {
        await Vue.http.post('/trainingsLists/assign', { trainingSlug, ownerId });

        await dispatch('GET_COMPANY_PROGRESS');

        return { success: true };
      } catch (error) {
        console.error(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    REVOKE_EMPLOYEE_FROM_THE_COURSE: async ({ dispatch }, { trainingSlug, ownerId }) => {
      try {
        await Vue.http.post('/trainingsLists/revoke', { trainingSlug, ownerId });

        await dispatch('GET_COMPANY_PROGRESS');

        return { success: true };
      } catch (error) {
        console.error(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    LOG_IN_ADMIN: async ({ commit }, { email, password }) => {
      try {
        const {
          data: {
            id: token,
            userId,
            ttl,
            created,
            role,
          }
        } = await Vue.http.post('/appuser/login', { email, password });

        commit('SET_SESSION_DATA', {
          token,
          userId,
          ttl,
          created,
          role,
        });

        return { success: true };
      } catch (error) {
        console.error(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      }
    },
    LOG_OUT: async ({ commit }) => {
      try {
        await Vue.http.post('/customers/logout');

        return { success: true };
      } catch (error) {
        console.error(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      } finally {
        commit('CLEAR_SESSION_DATA');
        commit('CLEAR_ACCOUNT_DATA');
        window.location.reload()
      }
    },
    LOG_OUT_ADMIN: async ({ commit }) => {
      try {
        await Vue.http.post('/appuser/logout');

        return { success: true };
      } catch (error) {
        console.error(error);

        const { message } = getValue(error, 'response.data.error', error);

        return { success: false, message };
      } finally {
        commit('CLEAR_SESSION_DATA');
        commit('CLEAR_ACCOUNT_DATA');
      }
    },
    CHANGE_LANGUAGE: async ({ state, commit, dispatch }, lang) => {
      if (lang !== 'en' && lang !== 'de') {
        console.error('wrong format of lang param, selecting default')
        lang = 'de'
      }

      const userLang = state.account.user.lang ? state.account.user.lang : 'de';
      if ( userLang !== lang ) {
        const {userId} = state.session;
        await Vue.http.patch(`/customers/${userId}`, { lang: lang });
      }

      await loadLanguageAsync(lang);
      commit('SET_LANGUAGE', lang);

      const match = router.currentRoute.matched.find(el => {
        return el.name === 'training'
      })
      // UPDATE COURSES ONLY IF WE ARE CURRENTLY IN THEM
      if (match) {
        await Promise.all([
            dispatch('GET_COURSE_DATA', router.currentRoute.params.slug),
            dispatch('GET_COURSE_PROGRESS', router.currentRoute.params.slug)
          ]);
      }
      return {success: true, message: 'success' };
    },
    OPEN_MODAL: async ({state, commit}, modal)  => {
      commit('SET_MODAL', {visible: true, modal })
    },
    CLOSE_MODAL: async ({state, commit}) => {
      commit('SET_MODAL', {visible: false, modal: {}})
      return true
    },
    GENERATE_DOWNLOAD_EXPORT: async ({state, commit}, lang) => {
      const res = await Vue.http.get(`/progress/download-company-progress?locale=${lang}`).catch(e => {
        console.error("couldnt get download export", e)
      })
      return res
    },
    CREATE_TRIAL_VOUCHER: async ({state, commit}, formData) => {
      return Vue.http.post(`/customers/create-trial-with-voucher/`, formData)
    },
    GET_CORVUS_QUOTE: (context, corvusQuoteId) => {
      return Vue.http2.get(`offers/get-corvus-quote/${corvusQuoteId}`)
    },
    REPORT_WEB_VITALS: (context, data) => {
      // console.log('reporting web vitals', data)
      // add timestamp to the data
      // add stage ID
      // in future we want to witch this off for local
      // in order to not clog the systens
      data.timestamp = new Date().toISOString()
      data.stage = process.env.NODE_ENV
      return Vue.http2.post(`webvitals/report`, data)
    }

  }
});
