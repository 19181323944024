<template>
  <div class="auth-page__holder change-password-page">
    <header class="auth-page__header">
      <div class="container">
        <div class="flex flex--between flex--v-center">
          <router-link
            area-label="logo"
            to="/"
          >
            <logo />
          </router-link>
        </div>
      </div>
    </header>

    <div class="auth-page__form">
      <h1 class="auth-page__title">{{ title }}</h1>
      <br>
      <form action="#"
            novalidate
            @submit.prevent="setPassword"
            autocomplete="off">
        <div class="auth-page__row">
          <label class="input__holder">
            <input type="email"
                   v-model="email"
                   readonly
                   name="email"
                   class="input">
            <span class="input__label"
                  :class="{ 'input__label--active': !!email }">{{ $t('common.email') }}</span>
          </label>
        </div>

        <div class="auth-page__row"
             :class="{ 'has-error': $v.form.password.$error }">
          <label class="input__holder">
            <input name="password"
                   type="password"
                   class="input"
                   v-model="form.password"
                   id="password"/>
            <span class="input__label"
                  :class="{ 'input__label--active': !!form.password }">{{ $t('common.password') }}</span>
          </label>
          <password-complexity :password="form.password"
                               @score="scoreChanged"/>
        </div>

        <div class="auth-page__row mb-4"
             :class="{ 'has-error': $v.form.confirmPassword.$error }">
          <label class="input__holder">
            <input name="confirm-password"
                   type="password"
                   class="input"
                   v-model="form.confirmPassword"
                   id="confirm-password"/>
            <span class="input__label"
                  :class="{ 'input__label--active': !!form.confirmPassword }">{{ $t('common.confirm_password') }}</span>
          </label>
        </div>

        <div :class="{ 'has-error': $v.form.dataProtectionPolicyAccepted.$error }">
          <div class="checkbox">
            <input name="accept_data-policy"
                   type="checkbox"
                   class="checkbox__input"
                   v-model="form.dataProtectionPolicyAccepted"
                   id="accept_data-policy"/>
            <label for="accept_data-policy"
                   class="checkbox__label"
                   v-html="$t('common.special_privacy_policy_checkbox')">
            </label>
          </div>
        </div>

        <div class="auth-page__buttons-holder mt-2">
          <button class="btn btn-primary btn--block btn--uppercase"
                  :class="{ 'btn--pending': passwordUpdatedPending }"
                  type="submit"
                  :disabled="$v.form.$error || passwordUpdatedPending">
            <icon name="sync"
                  class="btn__pending-icom"
                  color="white"
                  :rotate="passwordUpdatedPending"></icon>
            {{ $t('common.save') }}
          </button>
        </div>
      </form>
    </div>
    <main-footer></main-footer>
  </div>
</template>

<script>
  import { MINIMUM_PASSWORD_LENGTH } from '@/constants';
  import { required, minLength, sameAs } from "vuelidate/lib/validators";
  import Icon from '@/components/icon/Icon.vue';
  import Logo from '@/components/logo/Logo.vue';
  import MainFooter from '@/components/footer/MainFooter.vue';
  import PasswordComplexity from '@/components/password-complexity/PasswordComplexity.vue';

  export default {
    name: 'change-password',
    components: {
      Logo,
      Icon,
      PasswordComplexity,
      MainFooter
    },
    data() {
      return {
        form: {
          password: '',
          confirmPassword: '',
          dataProtectionPolicyAccepted: false,
        },
        title: this.$t('page.change_password_title'),
        passwordUpdatedPending: false,
        passwordScore: null,
      };
    },
    metaInfo() {
      return {
        title: this.title,
      }
    },
    validations: {
      form: {
        password: {
          required,
          minLength: minLength(MINIMUM_PASSWORD_LENGTH),
        },
        confirmPassword: {
          required,
          minLength: minLength(MINIMUM_PASSWORD_LENGTH),
          sameAs: sameAs('password'),
        },
        dataProtectionPolicyAccepted: {
          isChecked(value) { return value === true}
        }
      },
    },
    computed: {
      email() {
        return this.$route.query.email;
      },
    },
    methods: {
      async setPassword() {
        this.$v.$touch();

        if (this.$v.$error || this.passwordUpdatedPending) return;
        this.passwordUpdatedPending = true;

        if (this.passwordScore < 2) {
          this.passwordUpdatedPending = false;
          return this.$toasted.show(this.$t('change_password.password_too_weak'), { type: 'error' });
        }

        const {
          success: resetSuccess,
          message: resetErrorMessage,
        } = await this.$store.dispatch('RESET_PASSWORD', this.form.password);
        this.passwordUpdatedPending = false;

        if ( resetSuccess ) {
          const loginData = {
            email: this.email,
            password: this.form.password,
          };

          const { success, message } = await this.$store.dispatch('LOG_IN', loginData);

          if ( success ) {
            this.$router.push('/');
          } else {
             this.$toasted.show(message, { type: 'error' });
          }
        } else {
          this.$toasted.show(resetErrorMessage, { type: 'error' });
        }
      },
      scoreChanged(score) {
        this.passwordScore = score;
      },
    }
  }
</script>

<style lang="scss">
@import '@/styles/variables.scss';
@import '@/styles/form.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.auth-page__holder {
  display: flex;
  flex-direction: column;
}

.change-password-page {
  background: #15192d;

  .logo {
    max-width: 420px;
    margin: 12px 0;
  }

  .login-container {
    max-width: 478px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 50px;
  }

  .auth-page__header {
    box-shadow: none;
  }
}

.auth-page__form {
  background: $whiteColor;
  border-radius: 0.5rem;
  color: $default-text-color;
  padding: 3rem 2.5rem 2.5rem 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 36px !important;

  @include media-breakpoint-down(xs) {
    padding: 2rem 1.5rem;
  }

  .login-top {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 2rem;

    ::v-deep button {
      svg {
        margin-left: -0.5rem;
        margin-right: 0.25rem;
      }
    }
  }

  .form-group {
    width: 100%;
    margin-bottom: 1.5rem;

    &--icon-l {
      position: relative;

      .icon-l {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 14px;

        ::v-deep svg {
          color: $orangeTone;
        }
      }

      .form-control {
        padding-left: 2.75rem;
      }
    }

    &--icon-r {
      position: relative;

      .icon-r {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 14px;

        ::v-deep svg {
          color: $default-text-color;
        }
      }

      .form-control {
        padding-right: 2.75rem;
      }
    }

    .icon-primary {
      ::v-deep svg {
        color: $primary;
      }
    }
  }

  .form-control {
    border: 1px solid #ced9dfe5;
    background: #CED9DF33;
    height: 40px;
    font-size:  0.9rem;
  }

  .input-group-text {
    background: #CED9DF33;
    border-right: none;
    padding-right: 0;
  }

  .input-group-prepend {
    margin-right: 0;

    & + .form-control {
      border-left: none;
    }
  }

  .submit-btn {
    margin-bottom: 1.5rem;
    margin-top: 1rem;
    width: 100%;
  }

  &__bottom-links {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
  }
}

.has-error {
  .form-control {
    border: 2px solid $red;
  }
}

.b-footer-2 {
  margin-top: auto;
}

@media screen and (max-width: 450px) {
  .change-password-page {
    padding: 0 12px;

    .auth-page__form {
      margin: 0;
    }
  }
}
</style>
