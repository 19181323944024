<template>
  <div class="form-group industry-selector" :class="getFieldClass('industry')">
    <div class="industry-selector__search-block">
      <label for="industryString" class="industry-selector__search-label">In welcher Branche ist Ihr Unternehmen tätig?</label>
      <div class="industry-selector__search-bar-group">
        <input
          class="form-control industry-selector__input"
          type="text"
          id="industryString"
          name="industryString"
          placeholder="Hier eingeben"
          ref="industryField"
          v-model="industryString"
          autocomplete="off"
          v-validate="isBroker ? { required: true } : ''"
          @focusin="handleInputFocusIn"
          @focusout="handleTextInputFocusOut"
        />
        <typeahead
          id="checkout-typeahead"
          ref="checkoutTypeahead"
          :class="{'open': finalSearchResult.length, 'force-hide': forceHideDropdown}"
          :value="industryString"
          @input="handleSearchInput(industryString)"
          force-select
          ignore-case
          target=".industry-selector__input"
          :data="flattenIndustries"
          :limit="50"
          v-show="industryString && industryString.length > 1"
          item-key="l"
        >
          <template slot="item" slot-scope="props">
            <li v-for="(item, idx) in finalSearchResult" :key="'final-search-' + item.l">
              <a
                v-html="props.highlight(item)"
                role="button"
                class="dropdown-item"
                :class="{'active': activeFinalSearchResult === idx}"
                @click="handleInsutryOptionClick(props, item)"></a>
            </li>
            <li>
              <a
                class="dropdown-item dropdown-item--choose-overview"
                role="button"
                @click="showIndustriesPanel = true">
                Gewünschte Branche nicht gefunden? Nutzen Sie gerne unsere Suchhilfe.
              </a>

            </li>
          </template>
        </typeahead>

        <button
          type="button"
          class="search-btn"
          @click="showIndustriesPanel = true"
        >
          <Icon name="search" color="secondary" :width="28" :height="28" />
        </button>
      </div>
    </div>

    <div class="industry-selector__choose-block">
      <div
        class="industry-selector__choose-header"
        @click="showIndustriesPanel = !showIndustriesPanel"
        :class="{ 'prev-next-spacer': showPrev || showNext }"
      >
        <label for="" class="industry-selector__block-label"
          >Oder finden Sie die passende Branche durch unsere Suchhilfe</label
        >
        <button
          type="button"
          class="arrow-btn"
          :class="{ 'arrow-btn--opened': showIndustriesPanel }"
        >
          <Icon
            name="arrow-short-down"
            color="secondary"
            :width="16"
            :height="16"
          />
        </button>
      </div>
      <transition-group name="industries">
        <div class="overview-wrapper" key="overview-wrapper">
          <div
            class="industry-selector__choose-overview"
            v-show="showIndustriesPanel"
            ref="overview"
            :key="'choose-overview'"
            :class="{ 'prev-next-visible': showPrev || showNext }"
          >
            <div class="industry-selector__category-list" ref="categoryList">
              <template v-for="(selection, idx) in selections">
                <div
                  v-if="selection.subcategories"
                  :key="`col-${idx}-${selection.name}`"
                  :class="{ 'some-selected': selections[idx + 1] }"
                  class="industry-selector__subcategory-list"
                >
                  <div
                    v-for="subcategory in selection.subcategories"
                    :key="`row-${idx}-${subcategory.name}`"
                    @click="
                      handleChoosePlateClick(selections, idx, subcategory)
                    "
                    class="category-plate"
                    :class="{
                      'category-plate--selected':
                        selections[idx + 1] &&
                        selections[idx + 1].name === subcategory.name
                    }"
                  >
                    <div class="visual-line-elem"></div>
                    {{ subcategory.label || subcategory.name }}
                  </div>
                </div>
              </template>
            </div>
          </div>

          <div
            class="industry-selector__scroll-controls"
            v-if="showIndustriesPanel"
            :key="1"
            :class="{ 'prev-next-visible': showPrev || showNext }"
          >
            <transition-group name="industries">
              <button
                :key="2"
                type="button"
                class="arrow-side-btn left"
                v-if="showPrev"
                @click="scrollToPrev()"
              >
                <Icon
                  name="arrow-right"
                  color="secondary"
                  :width="32"
                  :height="32"
                />
              </button>
              <button
                :key="1"
                type="button"
                class="arrow-side-btn right"
                v-if="showNext"
                @click="scrollToNext()"
              >
                <Icon
                  name="arrow-right"
                  color="secondary"
                  :width="30"
                  :height="30"
                />
              </button>
            </transition-group>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
// TODO:
// - refactor

const { categories } = require('./categories.json') // why do we use require here?
// const synonyms = require('./industrySynonymsMap.json')
// categories.json was created based on "Kategorien Branchen.xlsx"
// If it will becoma a dynamic data, then it should be transformed to the same structure

function findNestedObjectByPair(obj, targetKey, targetValue, currentPath = []) {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const newPath = currentPath.concat(key);

            if (key === targetKey && obj[key] === targetValue && noSameNamedChildren(obj.subcategories, targetValue)) {
                return newPath;
            } else if (typeof obj[key] === 'object') {
                const nestedPath = findNestedObjectByPair(obj[key], targetKey, targetValue, newPath);
                if (nestedPath) {
                    return nestedPath;
                }
            }
        }
    }
    return null;
}

function noSameNamedChildren(subcategories, target) {
  if (!subcategories) {
    return true
  }
  const x = subcategories.filter(sub => sub.name === target)
  return x ? false : true
}

import eventBus from '@/components/shared/EventBus.js'
import { Typeahead } from 'uiv'
import Icon from '../../ui/Icon.vue'
import {uniqBy, isEqual} from 'lodash'
export default {
  components: {
    Typeahead,
    Icon
  },

  props: [
    'getFieldClass',
    'inputs',
    'isBroker',
    'orderedByLabel',
    'industries',
    'propIndustryString',
    'flattenIndustries',
    'isWhiteList'
  ],

  data() {
    return {
      industryString: '',
      // synonymMap: synonyms,
      finalSearchResult: [],
      activeFinalSearchResult: null,
      showIndustriesPanel: false,
      selections: this.getInitialSelections(),
      scrollPosition: this.getScrollPosition(),
      finalIndustry: {},
      showArrowButton: true,
      showPrev: true,
      showNext: true
    }
  },

  computed: {
    dynamicSynonyms() {
      const industryWithSynonyms = []
      this.industries.forEach(parentInd => {
        if (!parentInd.c) {
          console.error(`INDUSTRY SELECTOR ERROR: Cannot find parentInd.c in industry: "${parentInd.l}"` )
        }
        parentInd.c.forEach( i => {
          industryWithSynonyms.push({
            industry: i.l,
            w:i.w,
            synonyms: i.synonyms})
        })
      })

      return industryWithSynonyms
    },
    activeStep: {
      get() {
        return this.$store.state.checkout.application.activeStep
      },
      set(newValue) {
        this.$store.commit('SET_ACTIVE_STEP', newValue)
      }
    },

    xsBreakpoint () {
      return (this.$mq === "MQ1" || this.$mq === "xs" || this.windowWidth < 576) ? true : false
    },

    forceHideDropdown () {
      return !this.finalSearchResult.length || this.finalSearchResult[0].l === ''
    },

    showContactBanner () {
      return this.$store.getters.showContactBanner
    },
  },

  watch: {
    industryString(string) {
      this.$emit('updateIndustryString', string)
    },

    showIndustriesPanel(isVisible) {
      setTimeout(() => {
        // Cannot avoid this hardcode
        this.scrollPosition = this.getScrollPosition()
      }, 10)

      this.setStepperStyles()
      eventBus.$emit('inpustriesPanelToggle', isVisible)
    },

    showContactBanner(bool) {
      this.setStepperStyles()
    },

    selections: {
      async handler() {
        this.detectIfArrowsVisible()
        if (this.selections.length > 2) {
          this.$store.commit('SHOW_CONTACT_BANNER', true)
        } else {
          this.$store.commit('SHOW_CONTACT_BANNER', false)
        }

        if (this.selections.length > 1) {

          setTimeout(() => {
            // Cannot avoid this setTimeout
            // scroll to next column
            this.scroll(900)
          }, 10)

          const selectedIndustryLabel = this.selections[this.selections.length - 1].name

          if (!this.selections[this.selections.length - 1].subcategories) {
            let selectedIndustryObj = this.flattenIndustries.find(ind => ind.l === selectedIndustryLabel)
            if (!selectedIndustryObj) {
              console.error(`INDUSTRY SELECTOR ERROR: Cannot find selectedIndustry: "${selectedIndustryLabel}" in flattenIndustry array` )
            }
            this.$emit('industryChanged', selectedIndustryObj)
          } else {
            this.$emit('industryChanged', {l: '', w: '-1'})
          }

          this.finalIndustry = !this.selections[this.selections.length - 1]
            .subcategories
            ? this.selections[this.selections.length - 1].name
            : false
        }

      },
      deep: true
    },

    'inputs.industry'(industry) {
      if (!industry) {
        this.industryString = ''
      } else {
        this.industryString = industry.l
        delete industry.synonyms
      }

      this.setStepperStyles()
    },

    finalSearchResult(a, b) {
      if (!isEqual(a,b)) {
        this.activeFinalSearchResult = null
      }
    }
  },

  methods: {
    handleSearchInput(string) {
      this.finalSearchResult = []
      let synonymSearchResult = []
      const trimmedStr = string.trim()

      if (trimmedStr.length > 1) {
        const dummySearchResult = this.searchDummy(string)

        synonymSearchResult = this.searchInSynonyms(this.dynamicSynonyms, trimmedStr)

        function arrayUnionBy(arr1, arr2, identifier) {
          return uniqBy([...arr1, ...arr2], identifier)
        }

        const mergedSearchResult =
          arrayUnionBy(
            dummySearchResult,
            this.abcSortBy(synonymSearchResult, 'l'),
            'w')

        return this.finalSearchResult = mergedSearchResult
      }

      // as an alternative search could be activated after 1-2 sec
    },

    abcSortBy(array, byKey) {
      return array.sort((a, b) => {
        return a[byKey].localeCompare(b[byKey], 'de')
      })
    },

    handleInputFocusOut() {
      this.finalSearchResult = []
      if (this.inputs.industry.l) {
        this.industryString = this.inputs.industry.l
      }
    },

    searchDummy(str) {
      const modifiedStr = str.toLowerCase().split(' ').join('')

      const searchResult = this.flattenIndustries.filter(industry => {
        const modifiedInsdustryStr = industry.l.toLowerCase().split(' ').join('')
        return modifiedInsdustryStr.includes(modifiedStr)
      })

      let sortedResult = []

      // 1. String starts with search substr
      const group1 = searchResult.filter(industry => industry.l.toLowerCase().startsWith(modifiedStr))

      const rest1 = searchResult.filter(industry => !industry.l.toLowerCase().startsWith(modifiedStr))

      // 2. Word in string starts with search substr
      const group2 = rest1.filter(industry => {
          const words = industry.l.split(' ');
          return words.some(word => word.toLowerCase().startsWith(modifiedStr));
      });

      //  3. Rest of words containing substring
      const rest2 = rest1.filter(industry => {
          const words = industry.l.split(' ');
          return !words.some(word => word.toLowerCase().startsWith(modifiedStr));
      });

      const group3 = rest2

      sortedResult = [...group1, ...this.abcSortBy(group2, 'l'), ...this.abcSortBy(group3, 'l')]

      return sortedResult
    },

    searchInSynonyms(synonymMap, str) {
      const modifiedStr = str.split(' ').join('')
      // remove spaces for better search experience

      const result = synonymMap.filter((industryMap) => {
        if(!industryMap.synonyms) {
          return false
        }
        return industryMap.synonyms.some((syn) => {
          const modifiedSyn = syn.split(' ').join('')
          // remove spaces for better search experience

          return modifiedSyn.toLowerCase().includes(modifiedStr.toLowerCase())
        })
      })

      const industries = result.map((el) => {
        return this.flattenIndustries.find(industry => industry.w === el.w)
      })
        .filter(industry => !!industry)
        .reduce((acc, currentItem) => {
          if (!acc.some((item) => item.l === currentItem.l)) {
            acc.push(currentItem);
          }
          return acc;
        }, [])

      return industries
    },

    handleInsutryOptionClick(props, item) {
      props.select(item)
      this.$store.commit('SET_INDUSTRY', item)
      this.showIndustryTree()
      this.industryString = item.l
      this.finalSearchResult = []
    },

    scroll(x) {
      this.$refs.overview.scrollBy({
        left: x,
        behavior: 'smooth'
      })
    },

    scrollToNext() {
      const categoryLisEl = this.$refs.categoryList
      const list = categoryLisEl.children
      const overviewEl = this.$refs.overview
      const overviewPosition = overviewEl.getBoundingClientRect()

      let visibleMet = false
      let previousElPosition

      // get last el that visible
      for (let i = 0; i < list.length; i++) {
        const elPosition = list[i].getBoundingClientRect()
        let relativePosition = {}

        ;(relativePosition.top = elPosition.top - overviewPosition.top),
          (relativePosition.right = elPosition.right - overviewPosition.right),
          (relativePosition.bottom =
            elPosition.bottom - overviewPosition.bottom),
          (relativePosition.left = elPosition.left - overviewPosition.left)

        const isPartiallyInViewport =
          relativePosition.left <= overviewEl.offsetWidth &&
          relativePosition.right * -1 <= overviewEl.offsetWidth

        const isFullyInViewport =
          relativePosition.left > -1 && relativePosition.right < 1

        if (i + 1 === list.length && isFullyInViewport) {
          break
        }

        // if last visible & partially - scroll to it
        // if last visible & fully - scroll to next
        if (isPartiallyInViewport && !visibleMet) {
          visibleMet = true
        }

        if ((visibleMet && !isPartiallyInViewport) || i + 1 === list.length) {
          // previous el was last visible
          if (
            !previousElPosition.isFullyInViewport &&
            previousElPosition.isPartiallyInViewport
          ) {
            // scrol to end of prev
            this.scroll(previousElPosition.left)
          } else if (previousElPosition.isFullyInViewport) {
            this.scroll(relativePosition.left)
            // scroll to end of current i
          }
          break
        }

        previousElPosition = {
          ...relativePosition,
          isPartiallyInViewport,
          isFullyInViewport
        }
      }
    },

    scrollToPrev() {
      const categoryLisEl = this.$refs.categoryList
      const list = categoryLisEl.children
      const overviewEl = this.$refs.overview
      const overviewPosition = overviewEl.getBoundingClientRect()

      let visibleMet = false
      let previousElPosition

      // get last el that visible
      for (let i = list.length - 1; i >= 0; i--) {
        const elPosition = list[i].getBoundingClientRect()
        let relativePosition = {}

        ;(relativePosition.top = elPosition.top - overviewPosition.top),
          (relativePosition.right = elPosition.right - overviewPosition.right),
          (relativePosition.bottom =
            elPosition.bottom - overviewPosition.bottom),
          (relativePosition.left = elPosition.left - overviewPosition.left)

        const isPartiallyInViewport =
          relativePosition.left <= overviewEl.offsetWidth &&
          relativePosition.right * -1 <= overviewEl.offsetWidth

        const isFullyInViewport =
          relativePosition.left > -1 && relativePosition.right < 1

        if (i === 0 && isFullyInViewport) {
          break
        }

        // if last visible & partially - scroll to it
        // if last visible & fully - scroll to next
        if (isPartiallyInViewport && !visibleMet) {
          visibleMet = true
        }

        if ((visibleMet && !isPartiallyInViewport) || i === 0) {
          // previous el was last visible
          if (
            !previousElPosition.isFullyInViewport &&
            previousElPosition.isPartiallyInViewport
          ) {
            // scrol to end of prev
            this.scroll(previousElPosition.left)
          } else if (previousElPosition.isFullyInViewport) {
            this.scroll(relativePosition.left)
            // scroll to end of current i
          }
          break
        }

        previousElPosition = {
          ...relativePosition,
          isPartiallyInViewport,
          isFullyInViewport
        }
      }
    },

    scrollEventHandler($e) {
      try {
        this.scrollPosition = this.$refs.overview.scrollLeft
        this.detectIfArrowsVisible()
      } catch (err) {
        console.error('scrollEventHandler err:', err)
      }
    },

    detectIfArrowsVisible() {
      setTimeout(() => {
        const categoryLisEl = this.$refs.categoryList
        const list = categoryLisEl.children

        // check if first/last are fully visible
        const isFirstFullyVisible = this.isFullyVisible(list, 0)
        const isLastFullyVisible = this.isFullyVisible(list, list.length - 1)

        this.showPrev = isFirstFullyVisible ? false : true
        this.showNext = isLastFullyVisible ? false : true
      }, 10)
    },

    isFullyVisible(list, index) {
      const overviewEl = this.$refs.overview
      const overviewPosition = overviewEl.getBoundingClientRect()

      const El1Position = list[index].getBoundingClientRect()
      let relativePosition = {}

      ;(relativePosition.top = El1Position.top - overviewPosition.top),
        (relativePosition.right = El1Position.right - overviewPosition.right),
        (relativePosition.bottom =
          El1Position.bottom - overviewPosition.bottom),
        (relativePosition.left = El1Position.left - overviewPosition.left)

      const isFullyInViewport =
        relativePosition.left > -1 && relativePosition.right < 1
      return isFullyInViewport
    },

    getScrollPosition() {
      return this.$refs.overview ? this.$refs.overview.scrollLeft : 0
    },

    emitSubmit() {
      eventBus.$emit('submitStep', this.activeStep.id)
    },
    sortRecursively(categoriesArray) {
      const sortFunction = (a, b) => {
        return a.name.localeCompare(b.name, 'de')
      }

      categoriesArray.sort(sortFunction)

      categoriesArray.forEach((iteratedCategory) => {
        if (
          iteratedCategory.subcategories &&
          iteratedCategory.subcategories.length
        ) {
          this.sortRecursively(iteratedCategory.subcategories)
        }
      })

      // filter what was not included by industryWhiteList
      if (this.isWhiteList) {
        const whiteListedCategories = []
        this.flattenIndustries.forEach(industry => {
          industry.name = industry.l
          whiteListedCategories.push(industry)

        })
        categoriesArray = whiteListedCategories
      }

      return categoriesArray
    },

    isLastColFullyVisible() {
      const self = this

      function isInViewport() {
        const categoryList = self.$refs.categoryList.children
        const lastEl = categoryList[categoryList.length - 1]

        const rect = lastEl.getBoundingClientRect()
        return (
          rect.left >= 0 &&
          rect.right <=
            (window.innerWidth || document.documentElement.clientWidth)
        )
      }

      return new Promise((resolve) => {
        setTimeout(() => {
          // cant avoid setTimeout because have to wait for DOM to update
          resolve(isInViewport())
        }, 10)
      })
    },

    async checkIfShowArrows() {
      // ! check if last column visible and sroll if not
      const isLastColVisible = await this.isLastColFullyVisible()
    },

    isElementPartiallyInViewoprt(el, percentVisible) {
      let rect = el.getBoundingClientRect(),
        windowHeight =
          window.innerHeight || document.documentElement.clientHeight

      return !(
        Math.floor(
          100 - ((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100
        ) < percentVisible ||
        Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) <
          percentVisible
      )
    },
    getInitialSelections() {
      // here we have to update the categories from the static structure to the dynamic one
      // by recursive, mapping
      const catsUpdatedToTR20 = this.recursiveCat(categories)
      return [{subcategories: this.sortRecursively(catsUpdatedToTR20)}]
    },
    recursiveCat(cats) {
      cats.forEach(cat => {
        const match = this.flattenIndustries.find(ind => ind.w === cat.w)
        if(match) {
          cat.name = match.l
        } else if (cat.w && !match){
          console.error('no match for:', cat.name)
        }
        if(cat.subcategories) {
          this.recursiveCat(cat.subcategories)
        }
      })
      return cats
    },

    choosePlate(selections, idx, subcategory) {
      selections.splice(idx + 1, selections.length + 1, subcategory)
    },

    handleChoosePlateClick(selections, idx, subcategory) {
      this.finalSearchResult = []
      selections.splice(idx + 1, selections.length + 1, subcategory)
    },

    handleAllClicks(e) {
      // handle Search Input FocusOut
      if (e.target.id !== 'industryString' ) {
        e.stopPropagation()
        this.handleInputFocusOut()
      }
      //
    },

    setStepperStyles() {
      if (window && this.finalIndustry && this.showContactBanner) {
        if (this.$store.state.checkout.application.mode !== 'www') {
          if (this.xsBreakpoint) {
            this.showIndustriesPanel
            ? (document.getElementById('stepper_cta').style.top = '-240px')
            : (document.getElementById('stepper_cta').style.top = '-260px')
          }
        } else {
          if (!this.xsBreakpoint) {
            this.showIndustriesPanel
            ? (document.getElementById('stepper_cta').style.top = '-120px')
            : (document.getElementById('stepper_cta').style.top = '-20px')
          }
        }
      }
    },

    handleInputFocusIn(e) {
      !this.finalSearchResult.length && Object.keys(this.inputs.industry).length ? this.finalSearchResult.push(this.inputs.industry) : ''


      this.listenNavKeys()
    },

    handleTextInputFocusOut() {
      this.$refs.industryField.removeEventListener('keydown', this.navKeysListener)
    },

    navKeysListener(event) {

      const $activeEl = document.querySelector('#checkout-typeahead .dropdown-item.active')
      const $parentEl = document.querySelector('#checkout-typeahead .dropdown-menu');

      switch(event.key) {
        case 'ArrowUp':

          event.preventDefault()

          if ($activeEl && $parentEl) {
            this.arrowScrollHandle(event.key, $activeEl, $parentEl)
          }

          if (this.activeFinalSearchResult !== null && this.activeFinalSearchResult > 0) {
            this.activeFinalSearchResult--
          }
          break;
        case 'ArrowDown':

          event.preventDefault()

          if ($activeEl && $parentEl) {
            this.arrowScrollHandle(event.key, $activeEl, $parentEl)
          }
          if (this.activeFinalSearchResult !== null && this.activeFinalSearchResult > -1 && this.activeFinalSearchResult + 1 < this.finalSearchResult.length) {
            this.activeFinalSearchResult++
          } else if (this.activeFinalSearchResult === null) {
            this.activeFinalSearchResult = 0
          }
            break;
        case 'Enter':

          const activeItem = this.finalSearchResult[this.activeFinalSearchResult]
          event.preventDefault()
          this.$store.commit('SET_INDUSTRY', activeItem)
          this.industryString = activeItem.l
          this.finalSearchResult = []
          this.selections = this.getInitialSelections()
          this.showIndustriesPanel = false
          break;
      }
    },

    listenNavKeys() {
      if (document) {
        this.$refs.industryField.addEventListener('keydown', this.navKeysListener)
      }
    },

    arrowScrollHandle(eventKey, child, container) {
      const fixPosition = eventKey === 'ArrowDown' ? 50 : -50
      const scrollStep = eventKey === 'ArrowDown' ? 275 : -275

      const isVisible = function (ele, container) {
        const eleTop = ele.offsetTop + fixPosition
        const eleBottom = eleTop + ele.clientHeight

        const containerTop = container.scrollTop
        const containerBottom = containerTop + container.clientHeight

        // The element is fully visible in the container
        return (
            (eleTop >= containerTop && eleBottom <= containerBottom) ||
            // Some part of the element is visible in the container
            (eleTop < containerTop && containerTop < eleBottom) ||
            (eleTop < containerBottom && containerBottom < eleBottom)
        );
      };

      const isVisibleState = isVisible(child, container)

      if (!isVisibleState) {
        container.scrollTop = container.scrollTop + scrollStep;
      }
    },

    showIndustryTree() {
      this.selections = this.getInitialSelections()

      try {
        const path = findNestedObjectByPair(this.selections, 'name', this.inputs.industry.l)

        const sliced = path.slice(2, path.length - 1)

        for (let i = 0, y = 0, selection = 1; i < sliced.length / 2; i++, y += 2, selection++) {

          if (selection === 1) {
            this.choosePlate(this.selections, selection, this.selections[0]?.subcategories?.[sliced[y]])
          } else {
            this.choosePlate(this.selections, selection, this.selections[this.selections.length - 1]?.subcategories?.[sliced[y]])
          }
        }
      } catch(err) {
        console.error('Cannot find selected industry in industry tree', err)
      }
    }
  },

  mounted() {
    if (document && this.$refs.overview) {
      this.scrollEventListener = this.$refs.overview.addEventListener(
        'scroll',
        this.scrollEventHandler
      )
    }

    if (this.isWhiteList) {
      this.showIndustriesPanel = true
    }

    this.detectIfArrowsVisible()

    if (document) {
      addEventListener('click', this.handleAllClicks)
    }
  },

  destroyed() {
    if (document && this.$refs.overview) {
      this.$refs.overview.removeEventListener('scroll', this.scrollEventHandler)
    }

    if (document) {
      const $stepperCta = document.getElementById('stepper_cta')
      $stepperCta ? $stepperCta.style.top = 'auto' : ''

      removeEventListener('click', this.handleAllClicks)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../scss/variables';
@import '../../../../scss/form';
@import '../../../../scss/mixins';
@import '../../../../scss/button';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
.industry-selector {
  position: relative;

  &__search-block {
    margin-bottom: 24px;
  }

  &__search-label {
    font-weight: bold;
    margin-bottom: 8px;
    font-size: 18px;
  }

  &__select {
    appearance: none;
    padding-right: 50px;
    height: 3rem;

    &::-ms-expand {
      display: none;
    }
  }

  &__input {
    padding: 0.85rem 1.5rem;
    line-height: 21.86px;
    height: 48px;
    border: 1px solid rgba(157, 177, 186, 0.5);
    border-radius: 4px;

    &::placeholder {
      line-height: 30px;
      color: #454545;
    }
  }

  &__search-bar-group {
    position: relative;
  }

  .search-btn {
    position: absolute;
    right: 0;
    bottom: 0.5rem;
    display: flex;
    background: #edf1f3;
    border: none;
    padding: 0.125rem;
    border-radius: 0.25rem;
    margin-right: 0.5rem;
  }


  &__choose-block {
    position: relative;

    .industry-selector__block-label {
      margin-bottom: 0;
    }
  }

  &__choose-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    gap: 1rem;
    border: 1px solid rgba(157, 177, 186, 0.5);
    height: 48px;
    padding-left: 24px;
    font-size: 18px;
    width: 100%;
    cursor: pointer;

    &.prev-next-spacer {
      @include media-breakpoint-down(md) {
        margin-bottom: 4rem;
      }
    }

    .arrow-btn {
      margin-right: 0.375rem;

      .icon {
        transition: 0s;

        svg {
          transition: 0.1s;
        }

        &:hover {
          ::v-deep svg {
            color: $secondary;
          }
        }
      }
    }
  }

  &__scroll-controls {
    .arrow-side-btn {
      display: flex;
      background: #edf1f3;
      border: none;
      padding: 0.055rem;
      border-radius: 0.25rem;
      color: $secondary;

      .icon {
        transition: 0s;

        svg {
          transition: 0.1s;
        }
      }

      &:hover {
        color: #22769C;
      }

      &.right {
        position: absolute;
        right: 0.4rem;

        @include media-breakpoint-up(lg) {
          position: absolute;
          right: -4rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      &.left {
        position: absolute;
        left: 0;

        @include media-breakpoint-up(lg) {
          position: absolute;
          left: -4rem;
          top: 50%;
          transform: translateY(-50%);
        }

        .icon {
          transform: rotate(180deg);
        }
      }
    }

    &.prev-next-visible {
      .right,
      .left {
        top: -3rem;

        @include media-breakpoint-up(lg) {
          top: calc((100% - 4rem) / 2); // fixes arrow btn position
        }
      }
    }
  }

  .overview-wrapper {
    position: relative;
  }

  &__choose-overview {
    overflow-x: auto;
    // hide scrollbar
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    @include media-breakpoint-up(md) {
      &.prev-next-visible {
        // padding-bottom: 6rem;
      }
    }

    &::-webkit-scrollbar {
      display: none; // hide scrollbar
      // height: 10px;
      // width: 10px;
      // background: #edf1f3;
      // padding: 2px;
      // border-radius: 0.25rem;
    }

    &::-webkit-scrollbar-thumb {
      // background: #3aaee8;
      // height: 4px;
      // border-radius: 0.5rem;
      // border: 2px solid #edf1f3;
    }
  }

  &__category-list {
    display: flex;
    padding: 24px 0;
    gap: 36px;
    border-bottom: 1px solid rgba(157, 177, 186, 0.5);
  }

  &__subcategory-list {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    // width: 100%;
    height: fit-content;
    // mob
    min-width: 230px;
    width: 230px;

    &.some-selected {
      .category-plate:not(.category-plate--selected) {
        color: rgba(#454545, 0.5);
      }
    }

    &:not(:first-child) {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -0.75rem;
        width: 1px;
        height: 100%;
        border-left: 1px dashed #22769C;
      }
    }

    &:not(:last-child) {
      .category-plate--selected {
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          right: -0.75rem;
          transform: translateY(-50%);
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 100%;
          background: #22769C;
        }

        &::after {
          content: '';
          position: absolute;
          top: calc(50% + 0.5px);
          right: -1.5rem;
          transform: translateY(-50%);
          width: 16px;
          height: 1px;
          border-bottom: 1px dashed #22769C;
        }

        .visual-line-elem {
          position: absolute;
          bottom: 50%;
          right: -1.525rem;
          width: 1px;
          //height: 100vh;
          border-right: 1px dashed #22769C;
        }
      }
    }

    @include media-breakpoint-up(sm) {
      width: calc(100% / 2 - 1.5rem);
      min-width: calc(100% / 2 - 1.5rem);
    }

    @include media-breakpoint-up(lg) {
      width: calc(100% / 3 - 1.5rem);
      min-width: calc(100% / 3 - 1.5rem);
    }
  }

  .search-btn {
    display: flex;
    background: #edf1f3;
    border: none;
    padding: 0.125rem;
    border-radius: 0.25rem;
    margin-right: 0.5rem;
  }

  .arrow-btn {
    display: flex;
    background: #edf1f3;
    border: none;
    padding: 0.5rem;
    border-radius: 0.25rem;
    color: $secondary;

    .icon {
      transition: transform 0.3s;
      transform: rotate(0deg);
    }

    &--opened {
      .icon {
        transform: rotate(180deg);
      }
    }
  }

  .submit-btn {
    position: absolute;
    bottom: 2rem;
    right: 0;
    max-width: 285px;
    width: 100%;
    margin-left: auto;
    margin-top: 3rem;
  }

  .category-plate {
    background: #edf1f3;
    min-height: 3rem;
    height: fit-content;
    padding: 0.75rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: 0.2s;
    color: #454545;
    text-align: center;
    word-break: break-word;

    &:hover:not(.category-plate--selected) {
      color: #22769C;
    }

    &--selected {
      position: relative;
      background: #12658b;
      color: #fff;
    }
  }

  // transition animation
  .industries-enter-active,
  .industries-leave-active {
    transition: opacity 0.3s;
  }
  .industries-enter,
  .industries-leave-to {
    opacity: 0;
  }
}

.dropdown {
  &.force-hide {
    display: none;
  }
}

#checkout-typeahead ::v-deep {
  .dropdown-item {
    padding-top: 0;
    padding-bottom: 0;
    white-space: inherit;
    cursor: pointer;

    &--choose-overview {
      margin-top: 0.125rem;
      color: $primary;
      font-weight: 600;
    }
  }

  .dropdown-menu {
    max-height: 300px;
    overflow-y: scroll;
    z-index: 705;

    .dropdown-item {

      &:hover {
        background: #f8f9fa;
      }
      &.active {
        color: #212529;
      }
    }
  }
}
</style>
