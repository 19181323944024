<template>
  <div>
    <div
    v-if="mode === 'offer'"
    class="insurers-wrapper"
    :class="[isSticking ? 'insurers-wrapper--only-images' : '']"
    ref="abxref">
      <div class="empty-white--offer" />
      <Insurers
        :inputs="inputsData"
        :key="'insurers-' + insurers.length"
        :insurers="insurers"
        :synaxon="synaxon"
        :checkoutButtonIsVisible="false"
        :affiliateBroker="affiliateBroker"
        :order1="order1Mixin"
        :order2="order2Mixin"
        :isBroker="isBroker"
        :mode="mode"
        :offerSelection="offerSelection"
        :maxOffers="maxOffers"
        @updateOfferSelection="() => {}"
        @selectInsurer="() => {}"
        @setModalBody="setModalBody"
        :isOffer="isOffer"
        :isAffiliate="isAffiliateMixin"
        :isOnlyImages="isSticking"
      />
    </div>
    <div class="new-question-categories" id="question-categories-block">
      <div class="new-question-categories__content">
        <div class="accordion-list" :class="{ 'accordion-list--offer': mode === 'offer' }">
          <AccordionTable
            :key="cIndex"
            :isOpenOnInit="true"
            :id="`acc-group-${cIndex}`"
            :info="null"
            v-for="(category, cIndex) in categories"
            :mode="'brokerPortal'"
            class="page-break"
          >
            <template #header>
              {{ category.label }}
            </template>
            <template #body>
              <ChildAccordion
                v-for="(subcategory, subCIndex) in subcategoriesByParent[category.key]"
                :key="`acc-group-${cIndex}__acc-subgroup-${subCIndex}Q`"
                :id="`acc-group-${cIndex}__acc-subgroup-${subCIndex}`"
                :isOpenOnInit="emptyCategories.indexOf(subcategory.key) !== -1"
                :isForceOpenOnInit="emptyCategories.indexOf(subcategory.key) !== -1"
                :mode="mode"
              >
                <template #header>
                  <div class="trigger-wrap trigger-wrap--no-pad trigger-wrap--no-score">
                    <b-button
                      block
                      v-b-toggle="`acc-group-${cIndex}__acc-subgroup-${subCIndex}`"
                      variant="info"
                      class="text-left d-flex align-items-center btn-question-properties"
                      :class="[subCIndex % 2 === 0 ? 'btn-question-properties-1' : 'btn-question-properties-2', emptyCategories.indexOf(subcategory.key) === -1 ? 'low-opacity':'' ]"
                    >
                      <div class="arrow">
                        <Icon name="arrow-short-down" :width="12" :height="8" class=""></Icon>
                      </div>
                      <span>
                        {{ subcategory.label }}
                      </span>
                      <Icon
                        v-if="subcategory.info"
                        v-tooltip.top-start="subcategory.info"
                        @click.native.stop
                        class="info-icon"
                        name="info-2"
                      />
                    </b-button>
                  </div>
                </template>
                <template #body>
                  <div class="question-outer-wrapper">
                    <div class="empty-white sticky-col" :class="{ 'empty-white--offer': isOffer }"></div>
                    <div class="w-full margin-l-10">
                      <div
                        class="question-grid"
                        :class="{
                          'question-grid--single-col': insurers.length === 1 && !isOffer,
                          'question-grid--two-col-w': insurers.length === 2 && !isOffer,
                        }"
                      >
                        <div class="question-col" v-for="(ins, insIndex) in insurers" :key="insIndex" :class="isOffer ? '': 'question-col--non-offer'">
                          <template
                            v-if="
                              questionsMatrix[ins.insurerKey] &&
                              questionsMatrix[ins.insurerKey][subcategory.key] &&
                              questionsMatrix[ins.insurerKey][subcategory.key].length > 0
                            "
                          >
                            <div
                              v-for="(question, qIndex) in questionsMatrix[ins.insurerKey][subcategory.key]"
                              class="question-wrapper"
                              :key="qIndex"
                              :class="[subCIndex % 2 === 0 ? 'bg-color-1' : 'bg-color-2']"
                            >
                              <span>
                                <QuestionText
                                  :question="question"
                                  :external-state="forceOpenedCats.indexOf(subcategory.key) !== -1"
                                  :category-key="subcategory.key"
                                  @toggle="toggleCategoriesState"
                                />
                              </span>
                            </div>
                          </template>
                          <template v-else>
                            <div
                              class="question-wrapper question-wrapper--empty"
                              :class="[subCIndex % 2 === 0 ? 'bg-color-1' : 'bg-color-2']"
                            ></div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </ChildAccordion>
            </template>
          </AccordionTable>
        </div>
      </div>
    </div>

    <modal :closeable="true" v-if="modalBody" @close="modalBody=''" class="insurer__modal">
      <h3 slot="header" v-if="isVisibleModalHeader">
        Erläuterung zum Sideletter
      </h3>

      <div slot="body" v-html="modalBody" />
    </modal>
  </div>
</template>

<script>
import AccordionTable from '../../block/AccordionTable/AccordionTable.vue'
import ChildAccordion from '../../block/AccordionTable/ChildAccordion'
import ConditionCheck from '../../../../ConditionCheck.js'
import Insurers from '../../parts/Insurers.vue'
import { mapGetters } from 'vuex'
import tariffsWithScanMixin from '../../../../mixins/tariffsWithScanMixin.js'
import insurerSharedMethods from '../../../../mixins/insurerSharedMethods.js'
import QuestionText from './QuestionText.vue'
import Icon from '../../ui/Icon'
import Modal from '../../../../Modal.vue';

export default {
  name: 'questionCategories',
  mixins: [tariffsWithScanMixin, insurerSharedMethods],
  components: {
    Modal,
    AccordionTable,
    ChildAccordion,
    Icon,
    Insurers,
    QuestionText,
  },
  props: {
    mode: {
      type: String,
      default() {
        return this.$store.state.checkout.application.mode
      },
    },
    insurers: {
      type: Array,
      default() {
        return []
      },
    },
    inputsData: {
      type: Object,
      default() {
        return {}
      },
    },
    isOffer: {
      type: Boolean,
      default() {
        return false
      },
    },
    affiliateBroker: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
    offerSelection: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    offerModules: {
      type: Object || null,
      required: false,
      default() {
        return null
      },
    },
  },

  data() {
    return {
      isVisibleModalHeader: false,
      modalBody: "",
      lenghtLimt: 30,
      isSticking: false,
      forceOpenedCats: [],
    }
  },
  mounted() {
    window.addEventListener('scroll', this.checkPosition);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.checkPosition);
  },
  computed: {
    ...mapGetters(['style','usedQuestionsByInsurers']),
    isAffiliate() {
      return this.style === 'affiliate'
    },
    emptyCategories() {
      const cats = []
      Object.keys(this.usedQuestionsByInsurers).forEach((insurerKey) => {
        this.usedQuestionsByInsurers[insurerKey].forEach((question) => {
          if(cats.indexOf(question.categoryKey) === -1 && question.categoryKey) {

            const modifiedInputs = this.modifyInputs(this.inputsData, insurerKey)

            if (ConditionCheck.check(modifiedInputs, question.conditions, null, null, { passFollowUps: true })
            && ConditionCheck.check(modifiedInputs, question.orConditions, null, null, { passFollowUps: true })) {
              cats.push(question.categoryKey)
            }
          }
        })
      })
      return cats
    },
    questionsMatrix() {
      const questionsMatrix = {}

      Object.keys(this.usedQuestionsByInsurers).forEach((insurerKey) => {
        if (!questionsMatrix[insurerKey]) {
          questionsMatrix[insurerKey] = {}
        }
        // process the questions
        if (!this.usedQuestionsByInsurers[insurerKey]) {
          return
        }
        this.usedQuestionsByInsurers[insurerKey].forEach((question) => {
          // see if there is a cat of the question
          if (question.categoryKey) {
            if (!questionsMatrix[insurerKey][question.categoryKey]) {
              questionsMatrix[insurerKey][question.categoryKey] = []
            }
            // check Qs conditions
            const modifiedInputs = this.modifyInputs(this.inputsData, insurerKey)

            if (
                ConditionCheck.check(modifiedInputs, question.conditions, null, null, { passFollowUps: true })
                && ConditionCheck.check(modifiedInputs, question.orConditions, null, null, { passFollowUps: true })
            ) {
              questionsMatrix[insurerKey][question.categoryKey].push(question)
            }
          }
        })
      })
      return questionsMatrix
    },
    categoriesData() {
      return this.$store.state.checkout.application.questionCategories || []
    },
    categories() {
      return this.categoriesData.categories || []
    },
    subcategories() {
      const s = this.categoriesData.subcategories || []
      return s.sort((a, b) => a.position - b.position)
    },
    subcategoriesByParent() {
      const s = this.categoriesData.subcategories || []

      const filtered = s.reduce((acc, subcategory) => {
        if (!acc[subcategory.category_key]) {
          acc[subcategory.category_key] = []
        }
        acc[subcategory.category_key].push(subcategory)
        return acc
      }, {})

      // sort res
      Object.keys(filtered).forEach((key) => {
        filtered[key] = filtered[key].sort((a, b) => a.position - b.position)
      })
      return filtered
    },
    synaxon() {
      return this.affiliateBroker && this.affiliateBroker.agency === 'synaxon'
        ? {
            tooltip: {
              trigger: 'Exklusiv für Kunden von einsnulleins und SYNAXON*',
              text: ``,
            },
          }
        : null
    },
  },
  methods:{
    setModalBody(content, isVisibleHeader = false) {
      if (content) {
        this.modalBody = content;
        this.isVisibleModalHeader = isVisibleHeader;
      }

      return this;
    },
    checkPosition() {
      const target = this.$refs.abxref;
      if (target) {
        const rect = target.getBoundingClientRect();
        if (rect.top === 0) {
          this.isSticking = true
        } else {
          this.isSticking = false
        }
      }
    },
    toggleCategoriesState(catKey) {
      if (this.forceOpenedCats.indexOf(catKey) === -1) {
        this.forceOpenedCats.push(catKey)
      } else {
        this.forceOpenedCats = this.forceOpenedCats.filter((c) => c !== catKey)
      }
    },
    modifyInputs(inputs, insurerKey) {
      const modifiedInputs = { ...inputs }
      if (!modifiedInputs.selectedModules && this.offerModules) {
        modifiedInputs.selectedModules = { ...this.offerModules }
      }
      modifiedInputs.insurer = insurerKey
      return modifiedInputs
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../scss/variables';
@import '../../../../scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
// importing shared styles with propertyGrid
@import '../sharedAccordionStyles.scss';

// define a var
$light-blue-1: #e0f2fa;
$light-gray-1: #ececec;
$max-col-w: 40%;
// $gap: 15px;

.trigger-wrap {
  &--no-pad {
    padding-left: 0;
  }
}
.btn-question-properties {
  background: $primary;
  padding: 0.5rem 1rem;
  font-weight: bold;
  transition: 0.2s;
  border: 0;
  color: $blackColor;
}

.btn.btn-question-properties-1 {
  background-color: $light-blue-1;

  &:hover,
  &:focus,
  &:active {
    color: lighten($blackColor, 10%);
    background-color: darken($light-blue-1, 10%);
    box-shadow: 0 0 0 0.2rem $light-blue-1;
  }
}

.btn.btn-question-properties-2 {
  background-color: $light-gray-1;

  &:hover,
  &:focus,
  &:active {
    color: lighten($blackColor, 10%);
    background-color: darken($light-gray-1, 10%);
    box-shadow: 0 0 0 0.2rem $light-gray-1;
  }
}

.question-outer-wrapper {
  display: flex;
  flex-wrap: nowrap;
  flex-grow: 1;
}

.question-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
  grid-gap: 10px;

  &--single-col {
    grid-template-columns: repeat(auto-fit, minmax(20px, 100%));

    .question-col {
      width: auto;
    }
  }

  &--two-col-w {
    grid-template-columns: 1fr 1fr;
    .question-col {
      width: auto;
    }
  }
}

.question-col {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  // width: 100%;

  &--non-offer {
    width: 290px;
  }
}

.question-wrapper {
  font-size: 0.75rem;
  text-align: center;
  border-radius: 0.25rem;
  padding: 0.6rem;
  flex-grow: 1;

  &.bg-color-1 {
    background-color: $light-blue-1;
  }
  &.bg-color-2 {
    background-color: $light-gray-1;
  }

  &--empty {
    // background: #ffffff;
    opacity: 0.2;
    width: 100%;
  }
}

.empty-white {
  background: #ffffff;
  width: 100%;
  &--offer {
    width: 30%;
  }
}
.margin-l-10 {
  margin-left: 10px;
}

// make margin from left to 30%
.insurers-wrapper {
  display: grid;
  grid-template-columns: 23% 77%;

  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure it stays above other content */
  background-color: rgba(255, 255, 255, 0.854);

  &--only-images {
    background-color: rgba(255, 255, 255, 1);
    max-height: 80px;
  }

}

.low-opacity {
  opacity: 0.5;
}
</style>
