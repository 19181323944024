<template>
  <div class="precentage" :class="{'insurer-recommended': insurer?.key === recommendedInsurer}">
    <template v-if="this.progress === undefined || this.isHidden">
      <div class="recommendation-frame"></div>
    </template>
    <template v-else-if="this.progress !== 'winner' && this.progress < 100">
      <div class="recommendation-frame"></div>
      <div class="fill" :style="`width: ${progressValue}%`"></div>
      <div class="value">
        {{ progressValue }}%
      </div>
    </template>
    <template v-else-if="progress === 'winner'">
      <div class="recommendation-frame"></div>
      <div class="value">
        <img class="win-icon" src="../../../assets/img/tr2-winner-category.png" alt="100%">
      </div>
    </template>
    <div v-else class="value">
      {{ Math.round(progress) }}%
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    progress: [Number, String],
    insurer: Object,
    isHidden: Boolean,
    isPrintMode: {
      type: Boolean,
      default () {return false}
    }
  },

  data() {
    return {
      progressValue: this.isPrintMode ? Math.round(this.progress) : 0,
      recommendedInsurer: this.$store.state.offer?.offer?.insurer
    }
  },

  watch: {
    progress (newValue) {
      this.progressValue = 0

      if (this.isPrintMode) {
        this.progressValue = Math.round(newValue)
      } else {
        this.simulateProgress()
      }
    }
  },

  mounted() {
    this.progress === 'winner' ? '' : this.progress && !this.isPrintMode ? this.simulateProgress() : ''
  },

  methods: {
    simulateProgress() {
      const updateProgress = () => {
        if ((this.progressValue + 1 === 100) || this.progressValue === Math.round(this.progress)) {
          return;
        }
        this.progressValue += 1;
        requestAnimationFrame(updateProgress);
      }
      requestAnimationFrame(updateProgress);
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/print/precentage.scss';

.precentage {
  position: relative;
  background: #e0f2fa;
  height: 100%;
  padding: 0.5rem 0.5rem 0.5rem 0;
  border-radius: 0.25rem;

  .fill {
    height: 100%;
    border-radius: 0 0.25rem 0.25rem 0;
  }

  .value {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
  }

  .win-icon {
    width: 34px;
  }
}
</style>
