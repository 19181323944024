<template>
  <section class="contacts-section">
    <div class="contacts-section__body">
      <div>
        <div>
          <b>Sie können die gewünschte Branche nicht finden?</b>
        </div>
        <div>
          Melden Sie sich bei uns und wir helfen Ihnen persönlich weiter.
        </div>
      </div>

      <a
        class="contacts-section__to-contact"
        :href="contactLink"
        target="_blank"
      >
        Kontaktieren Sie uns

        <img src="../assets/img/icons/arrow-right-full.svg" alt="ico">
      </a>
    </div>
  </section>
</template>

<script>
import ButtonComponent from './calculator-v2.0/ui/ButtonComponent.vue'
export default {
  components: { ButtonComponent },
  props: {
    contactLink: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables";
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.contacts-section {
  max-width: 1218px;
  width: 100%;
  padding: 12px;
  background: #efeff0;
  margin: 0 auto;
  border-radius: 8px;
  font-size: 18px;

  .contacts-section__body {
    border-radius: 4px;
    box-shadow: 0 19px 7px 0 #7070700D,
                0 34px 4px 0 #70707003,
                0 53px 5px 0 #70707000;

    background: url('../assets/img/step1a.png') no-repeat left / 28%, #fff;
    padding-left: 320px;
    padding-right: 80px;
    height: 106px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #ebf0f2;
    gap: 2rem;
  }

  @include media-breakpoint-down(sm) {
    .contacts-section__body {
      flex-direction: column;
      gap: 2rem;
    }
  }

  .contacts-section__to-contact {
    white-space: nowrap;
    color: #f26722 !important;
    font-weight: 800;
  }
}
</style>
