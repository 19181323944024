export default {
  computed: {
    insurersWithIndication() {
      return this.$store.state.checkout.application.insurers.filter(i => i.isIndication).map(i => i.key)
    },
  },
  methods: {
    tariffHasIndicationMixin(tariffKey, insuredSum, netTurnOver) {
      const hasIndication = this.insurersWithIndication.includes(tariffKey)      
      const tariffData =  this.$store.state.checkout.application.insurers.find(i => i.key === tariffKey)
      const conditions = tariffData?.indicationConditions ? this.checkIndicationConditionsMixin(tariffData.indicationConditions, insuredSum, netTurnOver) : true
      return hasIndication && conditions
    },    
    checkIndicationConditionsMixin(indicationConditions, insuredSum, netTurnOver) {
      // Check if indicationConditions is null, undefined, or an empty object
      if (!indicationConditions || Object.keys(indicationConditions).length === 0) {
        return true
      }

      // Helper function to validate range object
      const isValidRange = (range) => range && typeof range?.gte === 'number' && typeof range?.lte === 'number'

      // Check netTurnOver conditions
      if (indicationConditions.netTurnOver) {
        const netTurnOverRange = indicationConditions.netTurnOver
        if (!isValidRange(netTurnOverRange) || typeof netTurnOver !== 'number') {
          return true // Invalid range or missing netTurnOver data
        }
        if (netTurnOver < netTurnOverRange.gte || netTurnOver > netTurnOverRange.lte) {
          return false // netTurnOver is out of range
        }
      }

      // Check insuredSum conditions
      if (indicationConditions.insuredSum) {
        const insuredSumRange = indicationConditions.insuredSum
        if (!isValidRange(insuredSumRange) || typeof insuredSum !== 'number') {
          return true // Invalid range or missing insuredSum data
        }
        if (insuredSum < insuredSumRange.gte || insuredSum > insuredSumRange.lte) {
          return false // insuredSum is out of range
        }
      }

      return true // All conditions are met
    },
  },
}
